import React from "react"
import LiveCatalog from "../components/Live/LiveCatalog"

const LiveCatalogPage = ({params}) => {
    return (
        <LiveCatalog categoryId={params["*"]} />
    );
}

export default LiveCatalogPage
